<script>
import { mapGetters } from 'vuex';
import { setTimeout } from 'timers';

export default ({
  data() {
    return {
      params: null,
      failedStatuses: [
        'VERIFY_FAILED',
        'FINALIZE_FAILED',
        'COMPLETE_FAILED',
        'DECLINED',
        'CANCELLED',
        'CANCELED',
        'DECLINED',
        'VERIFICATION_THRESHOLD_REACHED',
        'FINALIZATION_THRESHOLD_REACHED',
        'ERROR',
      ],
    };
  },

  created() {
    this.params = this.$route.query;

    this.setLoadingState();
    this.getStatus();
  },

  computed: {
    ...mapGetters('topbar', ['market']),
  },

  methods: {
    handleFailure(responseCode) {
      const action = this.market === 'SE' ? 'shipment/getV2' : 'shipment/get';

      this.$store
        .dispatch(action, { itemId: this.params.itemId, code: this.params.code })
        .then(() => {
          this.$store.commit('SET_DONE_LOADING');

          this.$router.push({
            name: 'payment',
            query: { ...this.$route.query, error: responseCode },
          });
        });
    },

    handleSuccess(status) {
      this.$store.commit('SET_DONE_LOADING');
      this.$store.dispatch('shipment/updateStatus', status);
      this.$router.push({
        name: 'receipt',
        query: this.$route.query,
      });
    },

    handleInvoice(status) {
      this.$store.commit('SET_DONE_LOADING');
      this.$store.dispatch('shipment/updateStatus', status);
      this.$router.push({
        name: 'invoice',
        query: this.$route.query,
      });
    },

    getStatus() {
      const sePayload = { itemId: this.params.itemId, code: this.params.code };
      const dkPayload = { request: { itemId: this.params.itemId, postCode: this.params.code } };
      const action = this.market === 'SE' ? 'shipment/getV2' : 'shipment/get';

      this.$store
        .dispatch(action, sePayload)
        .then((data) => {
          if (this.params.responseCode === 'Cancel' || this.params.responseCode !== 'OK') {
            this.handleFailure(this.params.responseCode);
            return;
          }

          if (this.market === 'SE') {
            this.getItemStatus('shipment/getV2', sePayload);
          } else {
            this.getItemStatus('shipment/getCartStatus', { ...dkPayload, cartId: data.item.cartId });
          }
        });
    },

    getItemStatus(action, payload) {
      setTimeout(() => {
        this.$store
          .dispatch(action, payload)
          .then((data) => {
            const item = data.item || data;

            if (this.params.paymentMethod === 'INVOICE') {
              this.handleInvoice('INVOICE-DONE');
            } else if (item.status === 'DONE') {
              this.handleSuccess(item.status);
            } else if (this.failedStatuses.indexOf(item.status) >= 0) {
              this.handleFailure('FAILED');
            } else {
              this.getItemStatus(action, payload);
            }
          })
          .catch(() => {
            this.handleFailure('Get item status failed');
          });
      }, 2500);
    },

    setLoadingState() {
      if (this.params.paymentMethod === 'SWISH-ECOMMERCE' && this.params.checkoutid === undefined) {
        this.$store.commit('SET_IS_LOADING', { title: 'SWISH_IN_PROGRESS', description: 'PLEASE_DONT_LEAVE' });
      } else if (this.params.responseCode === 'Cancel') {
        this.$store.commit('SET_IS_LOADING', { title: 'CANCEL_IN_PROGRESS', description: 'PLEASE_DONT_LEAVE' });
      } else {
        this.$store.commit('SET_IS_LOADING', { title: 'TRANSACTION_IN_PROGRESS', description: 'PLEASE_DONT_LEAVE' });
      }
    },
  },
});
</script>

<template />

<style lang="scss" scoped>
  .redirect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 100px auto 0;
  }
</style>
