<script>
import { mapGetters } from 'vuex';
import { GrowthBook } from '@growthbook/growthbook';
import ErrorMessage from '@/components/ErrorMessage.vue';
import DkPayment from './DkPayment.vue';
import SePayment from './SePayment.vue';
import SeCheckout from './SeCheckout.vue';

export default {
  data() {
    return {
      shouldUsePaymentCheckout: false,
      error: null,
      isPaying: false,
    };
  },

  created() {
    // If component is loaded with error in query it means we've been
    // redirected from the redirect component and should display the error
    const qError = this.$route.query.error;
    if (qError) {
      this.handleError(qError);
    }

    const growthbook = new GrowthBook({
      apiHost: process.env.VUE_APP_GROWTHBOOK_HOST_URL,
      clientKey: process.env.VUE_APP_GROWTHBOOK_CLIENT_KEY,
      enableDevMode: true,
      trackingCallback: (experiment, result) => {
        // TODO: Use your real analytics tracking system
        console.log('Viewed Experiment', {
          experimentId: experiment.key,
          variationId: result.key,
        });
      },
    });

    // Wait for features to be available
    growthbook.init({ streaming: true }).then(() => {
      this.shouldUsePaymentCheckout = growthbook.isOn('noc-nops-checkout') && this.market === 'SE';
    });

    // When a feature flag has changed, update.
    growthbook.setRenderer(() => {
      this.shouldUsePaymentCheckout = growthbook.isOn('noc-nops-checkout') && this.market === 'SE';
    });
  },

  computed: {
    ...mapGetters('topbar', ['market', 'language']),
    ...mapGetters('shipment', ['item']),
  },

  methods: {
    getComponent() {
      if (this.shouldUsePaymentCheckout && this.market.toLowerCase() === 'se') {
        return `${this.market.toLowerCase()}-checkout`;
      }

      return `${this.market.toLowerCase()}-payment`;
    },

    handleError(error) {
      if (error.response.status === 409) {
        if (error.response.data.errorCode === 'PAYMENT_ALREADY_FINALIZED') {
          // Already paid
          const code = this.market === 'SE' ? this.item.code : this.item.pinCode;
          this.$router.push({
            name: 'receipt',
            query: {
              itemId: this.item.itemId,
              code,
            },
          });
        }
        this.error = {};
        this.error.title = this.$t('PAYMENT_CONFLICT');
        this.error.description = this.$t('PAYMENT_CONFLICT_DESCRIPTION');
      } else if (error.response.status === 429) {
        this.error = {};
        this.error.title = this.$t('PAYMENT_DUPLICATE');
        this.error.description = this.$t('PAYMENT_DUPLICATE_DESCRIPTION');
      } else {
        this.error = {};
        this.error.title = this.$t('PAYMENT_FAILED');
        this.error.description = this.$t('PAYMENT_FAILED_DESCRIPTION');
      }
      window.scrollTo(0, 0);
    },

    initPayment(data) {
      const code = this.market === 'SE' ? this.item.code : this.item.pinCode;
      let action = this.market === 'SE' ? 'shipment/requestPaymentV2' : 'shipment/requestPayment';
      if (this.shouldUsePaymentCheckout) {
        action = 'shipment/callCreateCheckout';
        data.request.language = this.language === 'sv' ? 'sv_SE' : 'en_US';
      }

      this.isPaying = true;
      data.request.redirectUrl = this.getRedirectUrl(data);

      this.$store.dispatch(action, {
        request: data.request,
        item: {
          id: this.item.itemId,
          code,
        },
      })
        .then((requestedPayment) => {
          this.isPaying = false;
          if (data.request.paymentMethod === 'SWISH-ECOMMERCE' || data.request.paymentMethod === 'APPLE-PAY') {
            this.$router.push({
              name: 'payment-redirect',
              query: {
                itemId: this.item.itemId,
                countryCode: this.market,
                paymentMethod: data.request.paymentMethod,
                responseCode: 'OK',
                code,
              },
            });
          } else if (data.request.paymentMethod === 'INVOICE') {
            this.$router.push({
              name: 'invoice',
              query: {
                itemId: this.item.itemId,
                code,
              },
            });
          } else if (this.shouldUsePaymentCheckout) {
            // TODO: Go to Checkout payment method select
            window.location.href = requestedPayment.checkoutUrl;
          } else {
            window.location.href = requestedPayment.terminalUrl;
          }
        })
        .catch((err) => {
          this.isPaying = false;
          this.handleError(err);
          window.scrollTo(0, 0);
        });
    },

    getRedirectUrl() {
      const code = this.market === 'DK' ? this.item.pinCode : this.item.code;
      const baseUrl = this.market === 'DK' ? process.env.VUE_APP_DK_BASE_URL : process.env.VUE_APP_SE_BASE_URL;

      const redirectUrl = [
        window.location.protocol,
        '//',
        window.location.host,
        `/${baseUrl}`,
        `${this.$route.params.topbar}/`,
        'payment-redirect',
        `?itemId=${this.item.itemId}`,
        `&code=${code}`,
      ];

      return redirectUrl.join('');
    },
  },

  components: {
    SeCheckout,
    DkPayment,
    SePayment,
    ErrorMessage,
  },
};
</script>

<template>
  <div class="container">
    <h1>{{ $t('PAYMENT_HEADER') }}</h1>
    <section>
      <error-message v-if="error" :title="error.title" :description="error.description" />
    </section>

    <component :is="getComponent()" @onInitPayment="initPayment" :is-paying="isPaying" />
  </div>
</template>

<style lang="scss" scoped>
  .title {
    font-size: 3em;
    font-weight: $bold;
    display: block;
    margin-top: 0;
    margin-bottom: 3.2rem;
  }
</style>
