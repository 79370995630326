<script>
import { mapActions, mapGetters } from 'vuex';

import Loader from '@/components/Loader.vue';
import TopbarPortal from '@/components/TopbarPortal.vue';
import TopbarMarketWeb from '@/components/TopbarMarketWeb.vue';
import FooterMarketWeb from '@/components/FooterMarketWeb.vue';
import Stepper from '@/components/Stepper.vue';
import Sidebar from '@/components/Sidebar.vue';
import { TOPBAR_PREFIXES } from '../constants';
import Steps from '../market-steps';

export default {
  props: ['topbar'],

  computed: {
    ...mapGetters('topbar', ['market', 'language', 'isInitialized']),

    ...mapGetters(['isLoading']),

    topbarName() {
      return this.topbar === TOPBAR_PREFIXES.PORTAL ? 'portal' : 'market-web';
    },
  },

  watch: {
    language() {
      this.setupLanguage();
    },
  },

  methods: {
    ...mapActions('topbar', {
      initTopbar: 'init',
      setLanguage: 'setLanguage',
      setMarket: 'setMarket',
    }),
    ...mapActions('topbarPortal', {
      initTopbarPortal: 'init',
    }),
    ...mapActions('topbarMarketWeb', {
      initTopbarMarketWeb: 'init',
    }),

    steps(market) {
      return Steps[market].steps;
    },

    gotoFirstStep(market) {
      this.$router.push({
        name: Steps[market].steps[0].component,
        params: { topbar: this.topbar },
      });
    },

    onTopbarLoaded() {
      if (this.topbarName === 'portal') {
        this.initTopbarPortal(window.pnTopbar);
      } else {
        this.initTopbarMarketWeb();
      }
      this.initTopbar(this.topbarName);
      this.setupLanguage();
    },

    onMarketChanged(market) {
      this.setMarket(market);
      this.gotoFirstStep(market);
    },

    onLanguageChanged(language) {
      this.setLanguage(language);
    },

    setupLanguage() {
      this.$i18n.locale = this.language;
      document.documentElement.lang = this.$i18n.locale;
      document.title = this.$t('META_TITLE');
    },
  },

  components: {
    TopbarPortal,
    TopbarMarketWeb,
    FooterMarketWeb,
    Sidebar,
    Stepper,
    Loader,
  },
};
</script>

<template>
  <div>
    <template v-if="topbarName === 'market-web'">
      <topbar-market-web
        @onMarketChanged="onMarketChanged"
        @onLanguageChanged="onLanguageChanged"
        @onLoaded="onTopbarLoaded"
      />
    </template>

    <template v-if="topbarName === 'portal'">
      <topbar-portal
        @onMarketChanged="onMarketChanged"
        @onLanguageChanged="onLanguageChanged"
        @onLoaded="onTopbarLoaded"
      />
    </template>

    <div class="main-container" v-if="isInitialized">
      <sidebar id="sidebar" />
      <div class="main">
        <stepper :steps="steps('SE')" :show-stepper="true" v-if="market === 'SE'" key="se-market" />

        <stepper :steps="steps('DK')" :show-stepper="true" v-if="market === 'DK'" key="dk-market" />

        <div v-show="!isLoading">
          <router-view id="main-view" />

          <template v-if="topbarName === 'market-web'">
            <footer-market-web
              @onMarketChanged="onMarketChanged"
              @onLanguageChanged="onLanguageChanged"
              @onLoaded="onTopbarLoaded"
              class="footer-market-web no-print"
            />
          </template>

          <template v-if="topbarName === 'portal'">
            <pn-footer class="no-print" id="footer" />
          </template>
        </div>

        <div id="loader" v-show="isLoading">
          <loader />
        </div>
      </div>
    </div>

    <a v-if="market === 'SE'" href="//postnord.humany.net/tull-och-moms-se" />
  </div>
</template>

<style scoped lang="scss">
@media print {
  #topbar {
    display: none;
  }

  pn-side-menu {
    display: none;
  }
}

.main-container {
  display: flex;

  @media #{$phone} {
    flex-direction: column;
  }

  .main {
    flex: 1;

    #main-view {
      padding-top: 4.8rem;

      @media #{$phone} {
        padding-top: 1.6rem;
        padding-left: 1.6rem;
        padding-right: 1.6rem;

        h1 {
          padding: 0 1.6rem;
        }
      }
    }

    section {
      @media #{$phone} {
        padding: 0 1.6rem;
      }
    }

    #footer {
      margin: 6.4rem 0;
    }

    .footer-market-web {
      margin-top: 5em;
    }
  }
}
</style>
